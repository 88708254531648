import axios from "axios";

const isDev = process.env.NODE_ENV === "development"
export const staticUrl =
    isDev
        ? "http://localhost:3000"
        : "https://truwell.com/api";

class Api {
    constructor() {
        this.storageBaseUrl =
            staticUrl
    }

    async getStorage(path, params) {
        const headers = {};

        const jwt = localStorage.getItem("access_token");

        if (jwt) {
            headers.authorization = `Bearer ${jwt}`;
        }

        const url = new URL(path, this.storageBaseUrl)

        if (!isDev) {
            url.pathname = '/api' + url.pathname
        }

        const res = await axios({
            method: "get",
            url: url.toString(),
            params,
            headers,
        });

        return res.data;
    }

    async postStorage(path, data, params) {
        const headers = {};
        const jwt = localStorage.getItem("access_token");

        if (jwt) {
            headers.authorization = `Bearer ${jwt}`;
        }

        const url = new URL(path, this.storageBaseUrl)

        if (!isDev) {
            url.pathname = '/api' + url.pathname
        }

        const res = await axios({
            method: "post",
            url: url.toString(),
            headers,
            params,
            data,
        });

        return res["data"];
    }

    async deleteStorage(path, data, params) {
        const headers = {};
        const jwt = localStorage.getItem("access_token");


        const url = new URL(path, this.storageBaseUrl)

        if (!isDev) {
            url.pathname = '/api' + url.pathname
        }
        if (jwt) {
            headers.authorization = `Bearer ${jwt}`;
        }

        const res = await axios({
            method: "delete",
            url: url.toString(),
            headers,
            params,
            data,
        });

        return res["data"];
    }

    async patchStorage(path, data) {
        const headers = {};
        const jwt = localStorage.getItem("access_token");

        if (jwt) {
            headers.authorization = `Bearer ${jwt}`;
        }
        const url = new URL(path, this.storageBaseUrl)

        if (!isDev) {
            url.pathname = '/api' + url.pathname
        }

        const res = await axios({
            method: "patch",
            url: url.toString(),
            headers,
            data,
        });

        return res["data"];
    }

    async createSurvey(data) {
        return await this.postStorage("/survey", data)
    }

    async getOneSurvey(gamePin) {
        return await this.getStorage("/survey/" + gamePin)
    }

    async getSchools() {
        return await this.getStorage("/auth/schools")
    }

    async removeUser(id) {
        return await this.deleteStorage("/auth/school/" + id)
    }

    async getTeacherWellbeingSurveys() {
        return await this.getStorage("/survey/teacher-wellbeing")
    }

    async getPermahSurveys() {
        return await this.getStorage("/survey/permah")
    }

    async getInfo(gamePin) {
        return await this.getStorage("/survey/info/" + gamePin)
    }

    async isAdmin() {
        try {
            return await this.getStorage("/auth/isAdmin/")
        } catch (e) {
            return false
        }
    }

    async saveSurveyAnswer(data) {
        return await this.postStorage("/survey-answer", data)
    }

    async updateSurveyAnswer(id, rating) {
        return await this.patchStorage("/survey-answer/" + id, {rating})
    }

    async login(data) {
        try {
            const res = await this.postStorage("/auth/login", data)

            localStorage.setItem('access_token', res.access_token)
            return res
        } catch (e) {
            return {
                error: true,
                message: e.response.data.message
            }
        }
    }

    async register(data, isAdmin) {
        try {
            const res = await this.postStorage("/auth/register", data)

            if(!isAdmin) {
                localStorage.setItem('access_token', res.access_token)
            }
            return res
        } catch (e) {
            return {
                error: true,
                message: e.response.data.message
            }
        }
    }

    async changePassword(data) {
        try {
            const res = await this.postStorage("/auth/change-password", data)

            return res
        } catch (e) {
            return {
                error: true,
                message: e.response.data.message
            }
        }
    }

    async generatePin(data) {
        return await this.postStorage("/survey", data)
    }

    async status(data) {
        try {
            return await this.getStorage("/auth/status", data)
        } catch (e) {
            return {
                error: true,
                message: e.response.data.message
            }
        }
    }

    async updateUser(data) {
        try {
            return await this.patchStorage("/auth/", data)
        } catch (e) {
            return {
                error: true,
                message: e.response.data.message
            }
        }
    }
    async canAccessSurvey(id) {
        return await this.postStorage("/survey/canAccess/" + id)
    }

    async getMyAnswers(pinCode, sessionId, type) {
        return await this.getStorage("/survey/info/" + pinCode, {sessionId, type})
    }

    async getSurveyAnalysis(result, type) {
        return await this.postStorage('/survey/analysis', {result, type})
    }

    async completeSurvey(sub, pin) {
        return await this.postStorage("/survey/complete/" + pin, null, {sub})
    }
}

export {Api};
